<template>
  <div id="servePledge">
    <van-nav-bar title="修改服务承诺" left-arrow @click-left="goLastIndex" fixed placeholder :border="false" />
    <div style="margin-top: 2rem">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item name="1" title="提供免费搬楼服务" :value="free_moving_title[free_moving]">
          <van-radio-group v-model="free_moving" checked-color="#FF7963">
              <van-radio name="0">不提供</van-radio>
            <van-radio name="3">免费搬三层楼</van-radio>
            <van-radio name="7">免费搬七层楼</van-radio>
          </van-radio-group>
        </van-collapse-item>
        <van-collapse-item name="2" title="无物流地址" label="超出30公里的距离额外收费" :value="exceed_distance_title[exceed_distance]">
          <van-radio-group v-model="exceed_distance" checked-color="#FF7963">
            <van-radio name="3">3元/公里</van-radio>
          </van-radio-group>
        </van-collapse-item>
        <van-collapse-item name="3" title="提供物流仓储" :value="provide_warehouse_title[provide_warehouse]">
          <van-radio-group v-model="provide_warehouse" checked-color="#FF7963">
            <van-radio name="0">不提供</van-radio>
            <van-radio name="7">7天及以内</van-radio>
            <van-radio name="15">15天及以内</van-radio>
          </van-radio-group>
        </van-collapse-item>
        <van-collapse-item name="4" title="提供增值税普通发票" :value="provide_invoice_value[provide_invoice]">
          <van-radio-group v-model="provide_invoice" checked-color="#FF7963">
            <van-radio name="0">不可提供</van-radio>
            <van-radio name="1">可提供</van-radio>
          </van-radio-group>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="fixedBottom">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="changePromise">
        点击更改
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      free_moving: "",
      free_moving_title: {
        0: "不提供",
        3: "免费搬三层楼",
        7: "免费搬七层楼",
      },
      exceed_distance: "3",
      exceed_distance_title: { 3: "3元每公里" },
      provide_warehouse: "",
      provide_warehouse_title: {
        0: "不提供",
        7: "7天以内",
        15: "15天及以内",
      },

      provide_invoice: "",
      provide_invoice_value: ["不可提供", "可提供"],
      moving_show: false,
      activeNames: ["1"],
    };
  },
  methods: {
    goLastIndex() {
      this.$router.go(-1);
    },
    changePromise() {
      if (
        this.free_moving &&
        this.exceed_distance &&
        this.provide_warehouse &&
        this.provide_invoice
      ) {
        this.$http
          .put("/api/v2/worker/attach_promise", {
            free_moving: this.free_moving,
            exceed_distance: this.exceed_distance,
            provide_warehouse: this.provide_warehouse,
            provide_invoice: this.provide_invoice,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("更改成功");
              this.$router.go(-1);
            }
          });
      } else {
        this.$toast("请填写完整信息");
      }
    },
  },
  created() {},
};
</script>
<style lang="less">
#servePledge {
  .topBac {
    img {
      width: 100%;
    }
  }
  .van-radio {
    padding: 0.8rem 0;
    .van-radio__label {
      padding-left: 2rem;
    }
  }
}
</style>